

	<style>
    .gcat-item{
       float: left;
      height: 364px;
      position: relative;
      width: 298px;
      padding-left : 2px;
      padding-bottom: 2px;
      overflow: hidden;
    }
    .gcat-item img{
      width: 100%;
      height: 100%;
    }
    .gcat-item-title{
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 18px;
      height: 183px;
      line-height: 20px;
      padding-top: 150px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 298px;
      display: none;
    }
    .gcat-item a.gcat-item-title:hover{
      color: #fff;
      text-decoration: none;
    }
    .gcat-item:hover a.gcat-item-title{
      display: block;
      color:#fff;
    }

    .menu-item{
       float: left;
      /* height: 212px; */
      position: relative;
      /* width: 139px; */
      /* padding : 1px; */
      /* padding-left : 2px;
      padding-bottom: 2px; */
      overflow: hidden;
    }
    .menu-item img{
      width: 100%;
      height: 100%;
    }
    .menu-item-title{
      background: none repeat scroll 0 0 rgba(0, 0, 0, 0.7);
      color: #fff;
      font-size: 18px;
      height: 212px;
      line-height: 20px;
      padding-top: 55px;
      position: absolute;
      text-align: center;
      top: 0;
      width: 139px;
      display: none;
    }
    .menu-item a.menu-item-title:hover{
      color: #fff;
      text-decoration: none;
    }
    .menu-item:hover a.menu-item-title{
      display: block;
      color:#fff;
    }
  </style>
  
  
  <div style="position: absolute;
    right: 655px;
    top: 0px;  ">
    <div class="offcanvas">
      <ul id="menu-vietnamese-menu" class="main-nav">
        <li>
          <div class="menu-item" style="height: 143px;width: 50%;  border: 1px solid rgba(36, 32, 32, 0.9); text-align: center;padding-top: 55px; ">
            <!-- <a href="/" itemprop="name">
              TRANG CHỦ            
            </a> -->
            <!-- <a class="menu-item-title" href="/" style="height: 141px;">TRANG CHỦ</a> -->
          </div>
          <div class="menu-item" style="height: 143px;width: 50%;  border: 1px solid rgba(36, 32, 32, 0.9); text-align: center;padding-top: 55px;">
            DỰ ÁN
            <a class="menu-item-title" href="/products" style="height: 142px; border: 0px solid rgba(36, 32, 32, 0.9);width: 100%;">DỰ ÁN</a>
          </div>
          <div class="menu-item" style="height: 143px;width: 50%;  border: 1px solid rgba(36, 32, 32, 0.9); text-align: center;padding-top: 55px;">
            CHÚNG TÔI
            <a class="menu-item-title" href="/introduce" style="height: 142px; border: 0px solid rgba(36, 32, 32, 0.9);width: 100%;">CHÚNG TÔI</a>
          </div>
          <div class="menu-item" style="height: 143px;width: 50%; border: 1px solid rgba(36, 32, 32, 0.9); text-align: center;padding-top: 55px;">
            NGHỆ THUẬT
            <a class="menu-item-title" href="/nghethuat" style="height: 142px; border: 0px solid rgba(36, 32, 32, 0.9);width: 100%;">NGHỆ THUẬT</a>
          </div>
          
        </li>
      </ul>
    </div>
    <button class="offcanvas-toggle">
      <span></span>
      <span></span>
      <span></span>
    </button>
  </div>
  
  
  <div class="logo">
    <a href="/"><img src="/assets/Home_files/logo.png" title="Trang chủ"></a>

  </div>
  
  <div class="main-component">
    <div id="system-message-container">
    </div>

        <div class="jg_row jg_row2" *ngFor="let product of categoryProducts | sort:['weight']">
          <div class="jg_element_cat">
            <div class="jg_imgalign_catimgs">
              <img style="width: 598px;;height:auto; margin-bottom: 2px; margin-left: -2px;" [src]="getProductImage(product)" title="{{product.title}}" itemprop="image">
            </div>
          </div>
          <div class="jg_clearboth"></div>
        </div>
        
    </div>
  <div class="body-gioithieu">
    
  </div>
  
  <script type="text/javascript">
    (function ($) {
      'use strict';

      // Full-screen navigation
      var $offcanvasToggle = $('.offcanvas-toggle');
      var $offcanvas = $('.offcanvas');
      $offcanvasToggle.on('click', function () {
        $offcanvas.toggleClass('offcanvas--active');
        $offcanvasToggle.toggleClass('offcanvas-toggle--active');
      });

    }(jQuery));
  </script>
  
