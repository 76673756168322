import { Component, HostListener, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { Observable } from 'rxjs';
// import { ResizeService } from 'src/app/size-detector/resize.service';
declare var $: any;
import { delay, map } from 'rxjs/operators';
import { SCREEN_SIZE } from 'src/app/size-detector/screen-size.enum';
import { ResizeService } from 'src/app/size-detector/resize.service';

@Component({
  selector: 'products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  products: Observable<any[]>;
  searchTerm: string;
  scrHeight:any;
  scrWidth:any;
  

  // @HostListener('window:resize', ['$event'])
  // getScreenSize(event?) {
  //       this.scrHeight =0;
  //       this.scrWidth =0;
  //       this.scrHeight = window.innerHeight;
  //       this.scrWidth = window.innerWidth;
  //       console.log(' width mobile ' + this.scrWidth);        
  //       this.metaService.updateTag({
  //         name: 'viewport',
  //         content: 'width=' + window.innerWidth + ', initial-scale=0.3'
  //       }, 'name=viewport');
  // }
  constructor(
    public db: AngularFireDatabase,
    public globalService: GlobalService,
    public router: Router,
    private title: Title,
    private metaService: Meta,
    private resizeSvc: ResizeService
  ) {


    // this.getScreenSize();

    

    this.products = db.list('/categories', ref => ref.orderByChild('nghethuat').equalTo(true).limitToLast(200)).valueChanges().pipe(
      map(categories => 
        (categories as any[]).sort((a, b) => a.weight - b.weight) // Sắp xếp tăng dần
      )
    );

    this.globalService.searchTerm.subscribe((term) => {
      this.searchTerm = term;
    });
    
  }

  ngOnInit() {
    this.title.setTitle('Products');
    // this.meta.updateTag({ content: 'View all products' }, "name='description'");
    $(document).ready(function() {
        // Full-screen navigation
        var $offcanvasToggle = $('.offcanvas-toggle');
        var $offcanvas = $('.offcanvas');
        $offcanvasToggle.on('click', function () {
          $offcanvas.toggleClass('offcanvas--active');
          $offcanvasToggle.toggleClass('offcanvas-toggle--active');
        });
    });

    // if (this.router.url.includes('product')) {
    //   this.globalService.searchTerm.next('');
    // }
  }

  getProductImage(product:any) {
    if (product.thumbnail) {
      return product.thumbnail;
    } else {
      return '../../assets/placeholder.jpg';
    }
  }
}
